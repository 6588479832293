@import (reference) './index.less';

.ant-layout-sider-children {
  //background-color: @color-primary !important;
}

// Pageheader
.site-page-header {
  padding: 16px !important;
}

// Content
.content__wrapper {
  padding: 16px;
}

// Buttons
.ant-btn:not(.ant-btn-circle) {
  border-radius: @border-radius-full;
}

.ant-btn.ant-btn-default {
  border: 1px solid rgba(0, 68, 204, 0.2);
  color: @color-primary;
}

.ant-btn {
  box-shadow: none;
}

.btn-green {
  background-color: @color-accent !important;
  border: none !important;
  box-shadow: 4px 8px 10px rgba(72, 227, 48, 0.25) !important;
  transition: opacity 0.1s;

  &.rounded {
    border-radius: 100px !important;
    overflow: hidden !important;
    width: max-content !important;
    height: max-content !important;
    min-width: 0px !important;
    padding: 8px !important;

    span {
      width: max-content !important;
      padding: 0px !important;
    }
  }

  &:hover {
    opacity: 0.7 !important;
  }

  &:disabled,
  &[disabled] {
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
  }
}

.btn-outlined-white {
  border: 1px solid white !important;
  color: white !important;
}

.btn-green-outline {
  box-shadow: 4px 8px 10px rgba(72, 227, 48, 0.25) !important;
  border: 1px solid @color-accent !important;
  min-width: 100px;
  color: @color-accent !important;

  &:disabled,
  &[disabled] {
    box-shadow: none !important;
    border: none !important;
    background-color: #f5f5f5 !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.btn-outline-primary {
  border: 1px solid fade(@color-primary, 20%) !important;

  svg {
    color: @color-primary !important;
  }
}

.ant-btn-text {
  box-shadow: none !important;
}

// Input
.custom-searchbar {
  min-width: 280px;

  .ant-input-affix-wrapper {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

div.ant-input-number, div.ant-input-number:hover {
  overflow: hidden;
  border: 1px solid fade(@color-primary, 20%);
  border-radius: @border-radius-full;
  box-shadow: none;

  input.ant-input-number-input {
    border: none;

    &:focus {
      outline: none;
      border: none;
    }
  }

  &.ant-input-number-lg input {
    height: 34px;
  }
}

.ant-input-group-wrapper {
  .ant-input-affix-wrapper {
    border: 1px solid fade(@color-primary, 20%) !important;
    border-top-left-radius: @border-radius-full !important;
    border-bottom-left-radius: @border-radius-full !important;
  }

  .ant-btn {
    border: 1px solid fade(@color-primary, 20%) !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    svg {
      color: fade(@color-primary, 75%);
    }
  }
}

// Dashboard
.dashboard__task__clients {
  .ant-card-body {
    padding: 0;
  }
}
.dashboard__task__working {
  .ant-card-body {
    padding: 0;
  }
}
@media screen and (max-width: 560px) {
  .ant-card.ant-card-bordered {
    .ant-card-body {
      padding: 8px;
    }
  }
}

// New task global
.btn__new__task {
  color: @color-primary!important;
  background-color: @bg-color!important;
}

// Bug Report
.btn__report__bug {
  color: @bg-color!important;
}

// Table
.ant-table.ant-table-small .ant-table-thead > tr > th {
  padding: 1px 2px !important;
  white-space: nowrap;
}

.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin-top: 0;
}

.ant-table.ant-table-small .ant-table-body .ant-table-tbody > tr > td {
  padding: 0 2px;
}

// Table pagination
.ant-table-pagination.ant-pagination {
  margin: 32px 0 !important;
}

.table__mini {
  .ant-table {
    .ant-table-content table {
      border: none !important;

      .ant-table-thead {
        .ant-table-cell {
          background-color: white !important;
          color: @color-primary !important;
        }

        th::before {
          display: none !important;
        }
      }

      .ant-table-tbody {
        .ant-table-row {
          border-bottom: none !important;

          .ant-table-cell {
            border-right: none !important;

            &:last-child {
              border-right: none !important;
            }
          }

          .ant-table-column-sort {
            background-color: inherit !important;
          }
        }
      }
    }
  }
}

.table__dashboard {
  .ant-card-body {
    padding: 0 !important;
  }

  .ant-card-head {
    margin-bottom: 0 !important;
  }
}

// Modal
.ant-modal-content {
  border-radius: @border-radius-full;
  overflow: hidden;
  box-shadow: 0 0 20px 5px fade(@color-primary, 5%);
  padding: 0 24px;

  .ant-modal-header {
    border-bottom: 1px solid fade(@color-primary, 20%);
  }

  .ant-modal-body {
    border-bottom: 1px solid fade(@color-primary, 20%);
  }
}

.ant-drawer-close-left .ant-drawer-header .ant-drawer-title {
  margin-left: 1.5rem;
}

.ant-drawer-close-left .ant-drawer-header .ant-drawer-close {
  left: 0;
  right: unset;
}

.modal-full {
  top: @border-radius-full !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;

  .ant-modal-content {
    padding: 0;
    margin: 0;
  }
}

.modal-upload {
  .ant-modal-footer {
    border: none !important;
    padding: 0 0 16px 0;

    button {
      border-radius: 2px !important;
    }
  }
}

.ant-modal-confirm {
  .ant-modal-content {
    padding: 20px 24px;
    border-radius: 8px;

    > .ant-modal-close + .ant-modal-body .ant-modal-confirm-title {
      margin-right: 24px;
    }
  }

  .ant-modal-body {
    padding: 0;
    border: none;

    .ant-modal-confirm-btn {
      margin-top: 12px
    }
  }

  .ant-modal-footer {
    border: none;
    padding: 0;
    margin-top: 12px;
  }
}

// Form
.ant-form.ant-form-horizontal .ant-row.ant-form-item {
  align-items: center;
}
.ant-form-item-label label, .ant-form-item-label > span {
  color: fade(@color-primary, 75%) !important;
}

.ant-form .ant-row.ant-form-item.ant-form-item-height-auto {
  .ant-form-item-label > label {
    height: auto;
  }

  .ant-form-item-control-input {
    min-height: 1px;
  }
}

@media (max-width: 575px) {
  .ant-form.form-nowrap .ant-form-item {
    flex-wrap: nowrap;

    .ant-form-item-label {
      flex: 0 1 auto;
      padding: 0;

      > label {
        height: auto;
      }
    }

    .ant-form-item-control {
      flex: 1 1 0;

      .ant-select-selector {
        .ant-select-selection-overflow  {
          justify-content: end;
        }

        .ant-select-selection-placeholder {
          text-align: right;
        }
      }
    }
  }
}

.ant-input-affix-wrapper {
  border-radius: @border-radius-full;
  border: 1px solid fade(@color-primary, 20%);
}

.ant-input-disabled {
  border-radius: @border-radius-full !important;
  border: 1px solid fade(@color-primary, 20%);
}

.ant-radio-inner::after {
  top: 3.2px;
  left: 3.5px;
}

// Tag
.ant-tag {
  border-radius: @border-radius-full !important;
}

// Select & Dropdown
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: @border-radius-full;
  border: 1px solid fade(@color-primary, 20%);
}

.ant-select-dropdown,
.ant-dropdown-menu {
  border-radius: @border-radius-full !important;
}

.ant-select-item,
.ant-dropdown-menu-item {
  &:hover {
    background-color: fade(@color-primary, 20%) !important;
  }
}

.ant-select.ant-select-borderless:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0;
}

.ant-select-dropdown.no-padding .ant-select-item-option {
  padding: 0;
}

.ant-select-multiple.select-tag-custom-blue .ant-select-selector .ant-select-selection-item {
  background: rgba(0, 68, 204, 0.05);
  color: rgba(0, 68, 204, 0.85);
  border: none;

  .ant-typography {
    color: rgba(0, 68, 204, 0.85);
  }
}

// Card
.ant-card.ant-card-bordered {
  border: 1px solid fade(@color-primary, 10%) !important;
  border-radius: @border-radius-full !important;
  //box-shadow: 0 0 20px 5px fade(@color-primary, 5%);

  &.no-boarder {
    border: none !important;
  }
}

.ant-card-hoverable {
  border-color: rgba(0, 68, 204, 0.2) !important;
  span {
    font-size: 18px;
  }

  &:hover {
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    background-color: rgba(0, 68, 204, 0.8) !important;

    span {
      color: #ffffff !important;
    }
  }
}

.blue-card-light {
  box-shadow: none !important;

  &.dashboard {
    height: 250px !important;
    width: 250px !important;
    color: white !important;

    .ant-card-body {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.editor {
    .ant-card-body {
      padding: @border-radius-full !important;
    }
  }

  .ant-card-head {
    padding: 0 20px !important;
    min-height: 0 !important;
    border-bottom: none !important;
    background: fade(@color-primary, 5%) !important;

    .ant-card-head-title {
      padding: 5px !important;
      color: @color-primary !important;
    }
  }

  .ant-card-body {
    padding: 20px 20px !important;
  }

  &__text {
    font-size: 96px !important;
    color: @color-primary !important;
    font-weight: 100;
    text-align: center;

    &.green {
      color: @color-accent !important;
    }

    &.yellow {
      color: #ffaa00 !important;
    }
  }
}

.red-card-light {
  box-shadow: none !important;
  border: 1px solid #fff2f2 !important;

  &.dashboard {
    height: 250px !important;
    width: 250px !important;
    color: white !important;

    .ant-card-body {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.editor {
    .ant-card-body {
      padding: @border-radius-full !important;
    }
  }

  .ant-card-head {
    padding: 0 20px !important;
    min-height: 0 !important;
    border-bottom: none !important;
    background: fade(@color-red, 5%) !important;

    .ant-card-head-title {
      padding: 5px !important;
      color: @color-red !important;
    }
  }

  .ant-card-body {
    padding: 20px 20px !important;
  }
}

.blue-card-dark {
  background-color: @color-primary !important;
  box-shadow: none !important;
  color: white !important;

  .ant-card-head {
    padding: 0 20px !important;
    min-height: 0 !important;
    border-bottom: none !important;
    background: rgba(255, 255, 255, 0.1) !important;

    .ant-card-head-title {
      padding: 5px !important;
      color: white !important;

      .ant-typography {
        color: white !important;
      }
    }
  }

  .ant-typography {
    color: white !important;
  }

  .t-lg {
    color: #48e330 !important;
    font-size: 30px !important;
  }

  .t-md {
    color: #48e330 !important;
    font-size: 18px !important;
  }
}

.ant-dropdown .ant-menu > .ant-menu-item {
  height: auto;
  line-height: 22px;
  padding: 5px 12px;
  margin: 0;

  &:hover {
    background-color: fade(@color-primary, 20%) !important;
  }
}

// Tabs
.ant-tabs.tab-secondary > .ant-tabs-nav > .ant-tabs-nav-wrap {
  > .ant-tabs-nav-list svg {
    font-size: 20px;
    vertical-align: text-bottom;
    margin-right: 8px;
  }
}

.main-layout-tabs {
  .ant-tabs-tab {
    border-top-right-radius: @border-radius-full !important;
  }

  .tabs-toggle-btn {
    &.ant-btn {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

// Collapse
div.ant-collapse {
  overflow: hidden;
  border-radius: 4px;

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 4px 4px;
  }

  .ant-collapse-item {
    .ant-collapse-header {
      color: @color-primary;

      svg {
        //color: black;
      }
    }

    .ant-collapse-content {
      //border-color: fade(@color-primary, 50%);
    }

    &:last-child {
      //overflow: hidden;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.ant-collapse.no-padding .ant-collapse-content > .ant-collapse-content-box {
  padding-left: 0;
  padding-right: 0;
}

.collapse-with-pointer {
  &.ant-collapse-item-disabled {
    .ant-collapse-header {
      cursor: pointer !important;
    }
  }
}

.ant-card-body,
div.ant-collapse,
div.ant-collapse .ant-collapse-item {
  //border-color: fade(@color-primary, 50%);
}

div.ant-collapse .ant-collapse-item.collapse-danger {
  background-color: #fff2f0;
  border: 1px solid @color-red;
  border-radius: 8px;

  .ant-collapse-header {
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    color: #ff4d4f;
    svg {
      color: @color-red;
    }
  }

  .ant-collapse-content-box {
    padding-top: 4px;
  }
}

.ant-collapse.headless {
  background: transparent;

  > .ant-collapse-item {
    border: none;

    > .ant-collapse-header {
      display: none;
    }

    > .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }
}

// Date picker
.ant-picker {
  border-radius: @border-radius-full;
  border: 1px solid fade(@color-primary, 20%);

  &.ant-picker-small.ant-picker-short-format > .ant-picker-input {
    width: auto;
    > input {
      width: 80px;
    }
  }
}

//.bar-custom {
//  padding: 0 16px !important;
//  overflow-x: hidden;
//}

// Simplebar
.bar-custom {
  .simplebar-track.simplebar-vertical {
    background-color: fade(@color-primary, 20%) !important;
    border-radius: @border-radius-full;
  }

  .simplebar-scrollbar {
    &::before {
      background-color: @color-primary !important;
    }
  }
}

.bar-default {
  background-color: unset !important;
  .simplebar-track.simplebar-vertical {
    background-color: unset !important;
    border-radius: @border-radius-full;
  }

  .simplebar-scrollbar {
    &::before {
      background-color: #7f7f80 !important;
    }
  }
}

.ant-form-large .ant-form-item-label > label {
  height: auto !important;
}

// Editor card
.editor__card {
  .ant-card-body {
    padding: 0;
  }
}
.editor__content {
  background: rgba(0, 68, 204, 0.2);
  padding: 32px 0;
  // border-radius: 64px;
  // max-width: 960px;
  margin: auto;
}
// DraftJS editor
.rdw-editor-wrapper {
  padding: 0 !important;
  border: none !important;

  .rdw-editor-main {
    padding: 8px 16px;
    overflow-y: scroll;
    //max-height: 300px;
  }
}

[data-tippy-root] {
  //margin-top: 15px !important;
  a {
    svg {
      display: none !important;
    }
  }
}

// Integrations

.integrations__grid {
  padding: 16px;

  .integrations__card {
    .ant-card-body {
      min-height: 180px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.coupons_page_form {
  .ant-card {
    .ant-card-body {
      padding: 10px 20px !important;
      padding-bottom: 0 !important;
    }
    margin-bottom: 20px;
  }
}

.change-log-table {
  .ant-table-thead {
    tr {
      th {
        &:nth-child(2) {
          background-color: #fc6c6c !important;
        }
        &:nth-child(3) {
          background-color: #a5fc97 !important;
        }
      }
    }
  }
  .ant-table-tbody {
    tr {
      td {
        &:nth-child(2) {
          background-color: #fecece !important;
        }
        &:nth-child(3) {
          background-color: #d7f8d1 !important;
        }
      }
    }
  }
}


.ant-avatar {
  &:not(.ant-avatar-sm):not(.ant-avatar-lg) {
    width: 28px;
    height: 28px;
    line-height: 28px;
  }

  &.ant-avatar-lg {
    width: 34px;
    height: 34px;
    line-height: 34px;
  }

  &.ant-avatar-square {
    border-radius: 5px;
  }
}

.compact-list {
  white-space: nowrap;

  .ant-list-item-action {
    margin-left: 21px;
  }
}

.ant-divider.primary {
  background: rgba(0, 68, 204, 0.20);
}

.ant-page-header.primary {
  .ant-page-header-heading-title, .ant-page-header-back-button {
    color: @primary-color;
  }
}

.ant-empty-description {
  text-transform: lowercase;

  &:first-letter {
    text-transform: capitalize;
  }
}

.ant-badge.ant-badge-gray .ant-badge-count {
  background: #7f7f80;
}

.ant-dropdown-menu .ant-dropdown-menu-item.ant-menu-item-divider, .ant-menu .ant-menu-item.ant-menu-item-divider {
  padding: 0 !important;
  height: auto;
  border-bottom: 1px solid #e2e8f0;
  margin-left: 4px;
  margin-right: 4px;
  width: auto;
  box-sizing: border-box;
}

.ant-form.ant-form-vertical.form-box-outline {
  .ant-form-item {
    border: 1px solid #0044CC33;
    border-radius: 4px;
    padding: 4px 8px 0;

    .ant-col.ant-form-item-label {
      padding: 0;
      line-height: 1.3;

      > label {
        font-size: 12px;
      }

      .ant-form-item-no-colon {
        display: flex;
        align-items: center;
        gap: 6px;

        svg {
          font-size: 1.25em;
        }
      }

      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none;
      }

      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
          display: inline-block;
          margin-right: 4px;
          color: #ff4d4f;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
          margin-left: auto;
      }
    }

    .ant-form-item-control-input {
      line-height: 1;

      .ant-input-affix-wrapper, .ant-select-selector, .ant-picker, .ant-btn, div.ant-input-number {
        border: none;
        padding: 0 2px;
        outline: none;
        box-shadow: none;
      }

      .ant-select {
        .ant-select-selector {
          .ant-select-selection-placeholder {
            left: 2px;
          }
          .ant-select-selection-search {
            margin-left: 0;
          }
        }

        .ant-select-arrow {
          right: 0;
        }

        &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
          padding-right: 10px;
        }

        .ant-select-clear {
          right: -4px;
        }
      }

      .ant-picker-range {
        padding-right: 10px;

        .ant-picker-input>input {

        }

        .ant-picker-clear {
          right: -4px;
        }

        .ant-picker-range-separator {
          padding: 0 4px;
        }
      }

      .ant-picker .ant-picker-suffix {
        display: none;
      }

      .ant-picker .ant-picker-active-bar {
        margin: 0;
      }
    }

    &.form-item-horizontal {
      flex-direction: row;
      align-items: center;
      gap: 4px;
      padding-bottom: 4px;

      .ant-form-item-control-input {
        min-height: 28px;
      }
    }
  }
}

.ant-checkbox-group.checkbox-group-square .ant-checkbox-wrapper .ant-checkbox,
.ant-radio-group.radio-group-square .ant-radio-wrapper .ant-radio {
  display: none;
}

.ant-checkbox-group.checkbox-group-pill
  .ant-checkbox-wrapper
  .ant-checkbox
  .ant-checkbox-inner,
.ant-radio-group.radio-group-pill
  .ant-radio-wrapper
  .ant-radio
  .ant-radio-inner {
  border-radius: 50%;
}

.ant-checkbox-group.checkbox-group-pill
  .ant-checkbox-wrapper
  .ant-checkbox.ant-checkbox-checked
  .ant-checkbox-inner,
.ant-checkbox-group.checkbox-group-pill
  .ant-checkbox-wrapper:hover
  .ant-checkbox.ant-checkbox-checked
  .ant-checkbox-inner,
.ant-radio-group.radio-group-pill
  .ant-radio-wrapper:hover
  .ant-radio.ant-radio-checked
  .ant-radio-inner,
.ant-radio-group.radio-group-pill
  .ant-radio-wrapper
  .ant-radio.ant-radio-checked
  .ant-radio-inner {
  background-color: #fff;
  border-color: #fff;
}

.ant-radio-group.radio-group-pill .ant-radio-wrapper .ant-radio-inner:after {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  inset-inline-start: 21.5%;
  display: table;
  width: 5.7142857142857135px;
  height: 9.142857142857142px;
  border: 2px solid #fff;
  background-color: #fff;
  border-top: 0;
  border-inline-start: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  content: '';
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  margin-block: 0;
  margin-inline: 0;
  border-radius: 0;
}

.ant-checkbox-group.checkbox-group-pill
  .ant-checkbox-wrapper
  .ant-checkbox.ant-checkbox-checked
  .ant-checkbox-inner:after,
.ant-radio-group.radio-group-pill
  .ant-radio-wrapper
  .ant-radio.ant-radio-checked
  .ant-radio-inner:after {
  border-color: #0044cc;
  opacity: 1;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.ant-checkbox-group.checkbox-group-pill
  .ant-checkbox-wrapper
  .ant-checkbox.ant-checkbox-checked:after,
.ant-radio-group.radio-group-pill
  .ant-radio-wrapper
  .ant-radio.ant-radio-checked:after {
  display: none;
}

.ant-checkbox-group.checkbox-group-pill .ant-checkbox-wrapper svg,
.ant-radio-group.radio-group-pill .ant-radio-wrapper svg,
.ant-checkbox-group.checkbox-group-square .ant-checkbox-wrapper svg,
.ant-radio-group.radio-group-square .ant-radio-wrapper svg {
  display: inline-block;
  font-size: 1.6em;
  margin-bottom: 4px;
}

.ant-checkbox-group.checkbox-group-pill,
.ant-checkbox-group.checkbox-group-square,
.ant-radio-group.radio-group-pill,
.ant-radio-group.radio-group-square {
  display: inline-flex;
  flex-wrap: wrap;
  row-gap: 6px;
}

.ant-checkbox-group.checkbox-group-pill .ant-checkbox-wrapper,
.ant-radio-group.radio-group-pill .ant-radio-wrapper {
  background: rgba(0, 68, 204, 0.05);
  border-radius: 4px;
  padding: 4px 10px;
}

.ant-checkbox-group.checkbox-group-square .ant-checkbox-wrapper,
.ant-radio-group.radio-group-square .ant-radio-wrapper {
  background: #f3f3f3;
  border-radius: 6px;
  padding: 20px 20px;
  /*width: 180px;*/
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: break-word;
}

.ant-checkbox-group.checkbox-group-pill
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked,
.ant-radio-group.radio-group-pill .ant-radio-wrapper.ant-radio-wrapper-checked,
.ant-checkbox-group.checkbox-group-square
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked,
.ant-radio-group.radio-group-square
  .ant-radio-wrapper.ant-radio-wrapper-checked {
  background: #0044cc;
  color: white;
}

.ant-checkbox-group.checkbox-group-pill.read-only
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked,
.ant-radio-group.radio-group-pill.read-only
  .ant-radio-wrapper.ant-radio-wrapper-checked,
.ant-checkbox-group.checkbox-group-square.read-only
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked,
.ant-radio-group.radio-group-square.read-only
  .ant-radio-wrapper.ant-radio-wrapper-checked {
  background: #6479a1;
}

.ant-picker.ant-picker-short-format {
  padding-right: 1.4em;
}
.ant-picker.ant-picker-short-format .ant-picker-clear {
  right: 0;
}

.ant-picker.ant-picker-short-format > .ant-picker-input {
  width: auto;
}

.ant-picker.ant-picker-short-format > .ant-picker-input > input {
  width: 6.1em;
}

.ant-picker.ant-picker-short-format.ant-picker-yy > .ant-picker-input > input {
  width: 4.63em;
}

.ant-optional-label.ant-form-item .ant-form-item-label:before {
  content: '(Optional)';
  display: block;
  color: rgba(0, 0, 0, 0.5);
  font-weight: normal;
  font-size: 0.9em;
  line-height: 1;
}
