@import '~antd/es/style/themes/default.less';
@import (reference) './index.less';
@import './v1.less';
@import './billing-portal.less';
@import './typography.less';

////////////////////////////// Global styles

html {
  --priority-0-color: #84CCFF;
  --priority-1-color: #FEDF78;
  --priority-2-color: #F98F8C;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: @text-color;
  font-weight: 600 !important;
  margin-bottom: 1em !important;

  &.no-margin {
    margin-bottom: 0 !important;
  }
}

body,
html {
  overscroll-behavior: none;
}

.default-button {
  -webkit-appearance: button;
  background-color: initial;
  background-image: none;
  cursor: pointer;
  border: none;
}

////////////////////////////// Loader Variants

.spinnerWrapForce {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

////////////////////////////// Width Variants

.max {
  width: 100% !important;
}

.max-h {
  height: 100% !important;
}

////////////////////////////// Text Variants

.blue {
  color: #1890ff !important;
}

.red {
  color: red !important;
}

.sub {
  opacity: 0.6 !important;
  font-size: 12px !important;
}

.disabled {
  opacity: 0.3 !important;
}

.underline {
  text-decoration: underline !important;
}

.center {
  text-align: center;
}

.size-md {
  font-size: 20px !important;
}

.strong-link {
  font-weight: bold;
  text-decoration: underline !important;
}

////////////////////////////// Icon Variants

.icon-lg {
  font-size: 24px !important;
}

.icon-md {
  font-size: 18px !important;
}

////////////////////////////// Custom styles

.strong {
  font-weight: bold !important;
}

.primary {
  color: @primary-color !important;
}

.secondary {
  color: #9A9A9A;
}

.danger {
  color: @error-color;
}

.green {
  color: @color-accent !important;
}

.pointer {
  cursor: pointer !important;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.m-0 {
  margin: 0 !important;
  display: block;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-s {
  margin-bottom: 5px !important;
}

.mb-m {
  margin-bottom: 10px !important;
}

.mb-l {
  margin-bottom: 20px !important;
}

.mb-xl {
  margin-bottom: 40px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-m {
  margin-left: 10px !important;
}

.ml-l {
  margin-left: 20px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-m {
  margin-right: 10px !important;
}

.mr-l {
  margin-right: 20px !important;
}

.w-full {
  width: 100% !important;
}

.flex-1 {
  flex: 1 1 0 !important;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-none {
  flex: none;
}

@media (min-width: 576px) {
  .sm-w-auto {
    width: auto !important;
    max-width: 100% !important;
  }

  .sm-flex-1 {
    flex: 1 1 0 !important;
  }

  .sm-flex-auto {
    flex: 1 1 auto !important;
    max-width: 100% !important;
  }

  .sm-flex-none {
    flex: none !important;
    max-width: 100% !important;
  }
}

@media (min-width: 768px) {
  .md-w-auto {
    width: auto !important;
    max-width: 100% !important;
  }

  .md-flex-1 {
    flex: 1 1 0 !important;
  }

  .md-flex-auto {
    flex: 1 1 auto !important;
    max-width: 100% !important;
  }

  .md-flex-none {
    flex: none !important;
    max-width: 100% !important;
  }
}

@media (min-width: 992px) {
  .lg-w-auto {
    width: auto !important;
    max-width: 100% !important;
  }

  .lg-flex-1 {
    flex: 1 1 0 !important;
  }

  .lg-flex-auto {
    flex: 1 1 auto !important;
    max-width: 100% !important;
  }

  .lg-flex-none {
    flex: none !important;
    max-width: 100% !important;
  }
}

@media (min-width: 1200px) {
  .xl-w-auto {
    width: auto !important;
    max-width: 100% !important;
  }

  .xl-flex-1 {
    flex: 1 1 0 !important;
  }

  .xl-flex-auto {
    flex: 1 1 auto !important;
    max-width: 100% !important;
  }

  .xl-flex-none {
    flex: none !important;
    max-width: 100% !important;
  }
}

@media (min-width: 1600px) {
  .xxl-w-auto {
    width: auto !important;
    max-width: 100% !important;
  }

  .xxl-flex-1 {
    flex: 1 1 0 !important;
  }

  .xxl-flex-auto {
    flex: 1 1 auto !important;
    max-width: 100% !important;
  }

  .xxl-flex-none {
    flex: none !important;
    max-width: 100% !important;
  }
}

/////////////////////////////// AntD overrides

th.ant-table-cell {
  background-color: #f5f5f5 !important;
}

/////////////////////////////// Pages

// *** Auth Page ****

.auth {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  &__sub {
    text-align: center;
  }

  &__title {
    font-size: 32px;
    font-weight: lighter !important;
    margin-bottom: 20px !important;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    width: 100%;
  }

  &__button {
    min-width: 200px;
  }

  &__reset {
    opacity: 0.5;
    cursor: pointer;
    margin-bottom: 40px;
    display: block;
    color: @color-primary;
  }

  &__subtitle {
    text-align: center;
    margin-bottom: 30px;
    display: block;
  }
}

.arrow-an {
  .keyframes(fade-in;{
		0% { opacity: 0; }
		100% { opacity: 1; }
		});

  .animation(fade-in 0.2s linear infinite alternate);
}

.keyframes(@name; @arguments) {
  @-moz-keyframes @name {
    @arguments();
  }
  @-webkit-keyframes @name {
    @arguments();
  }
  @keyframes @name {
    @arguments();
  }
}

.animation(@arguments) {
  -webkit-animation: @arguments;
  -moz-animation: @arguments;
  animation: @arguments;
}

.animation-keyframes(@name, @from, @to, @anim-selector) {
  @keyf: ~"@keyframes @{name} { `'\n'`from ";
  @anim: ~"} `'\n'`.@{anim-selector}";
  @{keyf} {
    .from(@name,@from);
  }
  to {
    .to(@name,@to);
  }
  @{anim} {
    animation-name: @name;
  }
}

.read-only {
  pointer-events: none;
}

.ant-input:read-only {
  cursor: default;
}

.ant-input:read-only:focus {
  border-color: #d9d9d9;
  box-shadow: none;
}

.ant-input:read-only:hover {
  border-color: #d9d9d9;
}

.label-red {
  label {
    color: red !important;
  }
}

.comment-styles {
  span {
    color: blue;
  }
  white-space: pre-wrap !important;
}
.mention-styles {
  span {
    color: blue;
  }
}

.ant-popover {
  .ant-popover-inner {
    border-radius: 12px;

    .ant-popover-title {
      padding-top: 8px;
    }
  }
}

.ant-notification {
  z-index: 1090;
}

.suffix-svg {
  svg {
    color: grey !important;
    opacity: 0.6;
  }
}

.activity-log-item {
  border-radius: 5px;
  padding: 5px 10px;
  transition: background-color 0.2s;
  &:hover {
    background-color: #e2e2e2;
  }
}

.section-to-print {
  .print-title {
    display: none;
    text-align: center !important;
    font-size: 32px;
    margin-top: 50px;
  }
}

@page {
  margin-top: 2cm;
  margin-bottom: 2cm;
}
@media print {
  img {
    max-height: 200px !important;
    object-fit: contain;
  }

  .image-tool__caption {
    display: none !important;
  }

  .section-to-print {
    padding: 0 100px;

    .print-title {
      display: inline-block;
      line-height: 100%;
      margin-bottom: 50px !important;
    }
  }
}

.ant-comment {
  .ant-comment-actions {
    line-height: 1;
  }

  > .ant-comment-inner {
    padding: 10px 0;
  }
}

.fc-daygrid-day-number,
.fc-col-header-cell-cushion {
  cursor: default !important;
}

.custom-project-select-layout {
  .customer-selected {
    display: none;
  }

  .customer-bottom {
    display: inline-block !important;
  }

  .ant-select-selection-item {
    .customer-selected {
      display: block !important;
    }

    .customer-bottom {
      display: none !important;
    }
  }
}

.Notifications {
  svg {
    height: 42px !important;
    width: 42px !important;
  }
}

.show-in-print {
  display: none !important;
}

@media print {
  .print-form-styles {
    padding: 0 50px;

    .ant-form-item-label > label.ant-form-item-required::before {
      display: none;
    }

    .ant-form-item-control-input-content .ant-input {
      border: none;
      resize: none;
      min-height: auto !important;
    }

    .ant-select-selector {
      border: none !important;
    }

    .ant-select .ant-select-arrow {
      display: none;
    }

    .ant-picker {
      border: none !important;

      .ant-picker-suffix {
        display: none;
      }
    }
  }

  .show-in-print {
    display: block !important;
  }

  .hide-in-print {
    display: none !important;
  }
}

.task-modal-collapse {
  border: none !important;
  border-bottom: none !important;

  .ant-collapse-item {
    border-bottom: none !important;

    .ant-collapse-header {
      background: white !important;
      border: 1px solid rgba(0, 68, 204, 0.2);
      border-radius: 4px !important;
    }

    .ant-collapse-content {
      border-top: none !important;
    }
  }
}

.ant-upload-list-picture-container .ant-upload-list-item-progress {
  bottom: 5px;
}

.ant-upload-list-item-card-actions-btn .anticon-delete {
  color: #ff4d4f !important;
}

.ql-editor {
  font-size: 16px;
}

.ql-editor ul > li::before {
  font-size: 24px;
  line-height: 1;
  width: 1.2em;
  vertical-align: middle;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 0;
}

.form-item-full-height > .ant-form-item-control > .ant-form-item-control-input, .form-item-full-height > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content {
 height: 100%;
}

.h-full {
  height: 100%;
}

.h-auto {
  height: auto;
}

.form-item-label-full .ant-form-item-label > label {
  width: 100%;
}

body .ant-radio-button-wrapper {
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
}

.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
  top: 0!important;
}

@media screen and (max-width: 576px) {
  .hMobile{
    display: none!important;
  }

  .pt-mobile-m{
    padding-top: 40px!important;
  }

  .ant-layout > .ant-layout-content > .ant-card > .ant-card-body {
    padding: 8px 12px;
  }

  .ant-tabs.ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 10px;

    > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 18px;
    }
  }
}

.timelog__form {
  text-align: left;

  .ant-form-item {
    margin-bottom: 0!important;
  }

  .timelog__form__items {
    @media screen and (max-width: 992px) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }
}

.budget-title {
  font-size: 14px;
  color: #888888;
  margin-bottom: 0;
}

.budget-text{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .ant-form-vertical.ant-form .ant-form-item {
    .ant-form-item-label {
      flex: 0 1 auto;
    }

    .ant-form-item-control {
      flex: 1 1 auto;
    }
  }
}

.app-scrollbar, .page-scrollbar {
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: fade(@color-primary, 20%);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #668fe0;
  }

  &::-webkit-scrollbar-thumb:hover {
     background-color: #4571c5;
  }
}

.page-scrollbar {
  &::-webkit-scrollbar-track {
    background-color: #fefefe;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb:hover {
     background-color: rgba(0, 0, 0, 0.4);
  }
}

.scroll-y {
  overflow-y: auto;
}

.cursor-grabbing {
  cursor: grabbing !important;
}

.ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
  .ant-badge {
    color: inherit;
  }
}

.ant-tabs.ant-tabs-top.tab-fullscreen > .ant-tabs-nav > .ant-tabs-nav-wrap:not(.ant-tabs-nav-wrap-ping-right):not(.ant-tabs-nav-wrap-ping-left) {
  > .ant-tabs-nav-list {
    flex: 1;

    > .ant-tabs-tab {
      flex: 1 1 auto;
      justify-content: center;
    }
  }
}

.ant-tabs.tab-secondary > .ant-tabs-nav > .ant-tabs-nav-wrap {
  > .ant-tabs-nav-list {
    color: #9A9A9A;
  }
}

.ant-select.ant-select-borderless .ant-select-selector .ant-select-selection-search {
  left: 0;
}

.ant-tabs-dropdown .ant-tabs-dropdown-menu .ant-tabs-dropdown-menu-item {
  svg {
    vertical-align: text-top;
    margin-right: 6px;
  }

  .ant-badge {
    width: 100%;

    .ant-badge-count {
      transform: none;
    }
  }
}

.ant-btn.ant-btn-icon-center {
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    line-height: 0;
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;